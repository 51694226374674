// // hello
// import Image from 'next/image';
// import React from 'react';
// import { loader, loaderCircle } from '../../../assets/icons';
// import styles from './button.module.scss';
// const Button = ({
//   type,
//   label,
//   onClick,
//   onMouseOver,
//   disabled,
//   loading,
//   loadingText,
//   className,
//   loadingIcon,
//   btnIcon,
// }) => {
//   return (
//     <button
//       type={type || 'button'}
//       onClick={onClick}
//       onMouseOver={onMouseOver}
//       disabled={loading || disabled}
//       className={[styles.btn, className].join(' ')}
//       style={
//         loading || disabled
//           ? { cursor: 'not-allowed', background: 'var(--neutral-mid)' }
//           : null
//       }
//     >
//       {loading ? (
//         <div className={styles.loading__container}>
//           <div>
//             {<Image src={loader} height={40} width={40} alt="loading..." /> ||
//               'icon'}
//           </div>
//         </div>
//       ) : (
//         <div className={styles.btn__icon__container}>
//           {btnIcon && <Image src={btnIcon} alt="stakecut" />}
//           <div>{label || 'Button Text'}</div>
//         </div>
//       )}
//     </button>
//   );
// };

// export default Button;

// hello
import Image from 'next/image';
import React from 'react';
import { loader } from '../../../public/assets/icons';
import styles from './button.module.scss';

const Button = ({
  type,
  label,
  onClick,
  onMouseOver,
  disabled,
  loading,
  className,
  btnIcon,
  textStyle,
}) => {
  return (
    <button
      type={type || 'button'}
      onClick={onClick}
      onMouseOver={onMouseOver}
      disabled={loading || disabled}
      className={[styles.btn, className].join(' ')}
      style={
        loading || disabled
          ? { cursor: 'not-allowed', background: 'var(--neutral-mid)' }
          : null
      }
    >
      {loading ? (
        <div className={styles.loading__container}>
          <div>
            {(
              <Image
                src={loader}
                height={40}
                width={40}
                alt="Stakecut | One stop Affiliate Marketplace"
              />
            ) || 'icon'}
          </div>
        </div>
      ) : (
        <div className={styles.btn__icon__container}>
          {/* look at mr-3 for fault */}
          {btnIcon && (
            <span className="mr-3 flex items-center justify-center">
                <Image
                  src={btnIcon}
                  alt="Stakecut | One stop Affiliate Marketplace"
                />
            </span>
          )}
          <div style={{ marginRight: 0, fontSize: textStyle }}>
            {label || 'Button Text'}
          </div>
        </div>
      )}
    </button>
  );
};

export default Button;
